<template>
  <div class="ChangelogOverview">
    <span class="Title">{{ mixWB('APP_CHANGELOG') }}</span>
    <div class="ButtonWrap">
      <Button
        :text="mixWB('CREATE_NEW')"
        :fullWidth="false"
        @button-click="onNewUpdateClick" />
    </div>

    <!-- Loading -->
    <div
      v-if="!allChangelogsLoaded"
      class="Loading">
      <Circular
        size="medium" />
    </div>

    <!-- List -->
    <div
      v-else
      class="UpdateList">
      <div
        class="ChangelogItem"
        v-for="changelog in allChangelogs"
        :key="changelog.id">
        <div class="LeftSide">
          <span>{{ changelog.version }}</span>
        </div>
        <div class="RightSide">
          <span @click="onEditClick(changelog)">{{ mixWB('EDIT') }}</span>
        </div>
      </div>
    </div>

    <Dialog
      :isShowing="showAddEditChangelog"
      :useComponent="AddChangelogModal"
      :componentProps="{
        changelogToEdit,
      }"
      size="medium"
      @close="showAddEditChangelog = false" />
  </div>
</template>

<script>
import Button from '@/components/Buttons/Button.vue'
import AddChangelogModal from '@/components/Changelog/AddChangelogModal.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import { mapGetters } from 'vuex'
import Circular from '@/components/Progress/Circular.vue'

export default {
  name: 'ChangelogOverview',
  data() {
    return {
      AddChangelogModal,
      showAddEditChangelog: false,
      changelogToEdit: false,
    }
  },
  computed: {
    ...mapGetters([
      'allChangelogsLoaded',
      'allChangelogs',
    ]),
  },
  methods: {
    getChangelogsOnLoad() {
      this.$store.dispatch('getChangelogs')
    },
    onNewUpdateClick() {
      this.changelogToEdit = false
      this.showAddEditChangelog = true
    },
    onEditClick(changelog) {
      this.changelogToEdit = changelog
      this.showAddEditChangelog = true
    },
  },
  components: {
    Button,
    Dialog,
    Circular,
  },
  created() {
    this.getChangelogsOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .ChangelogOverview
    width 800px
    max-width 90%
    margin 50px auto 0px
    padding 20px
    background-color #fff
    box-shadow $box_shadow_1

  .Title
    text-transform uppercase
    font-size 2rem
    text-align center
    margin-bottom 30px

  .ButtonWrap
    display flex
    justify-content flex-end
    margin-bottom 10px

  .Loading
    position relative
    height 200px

  .ChangelogItem
    display flex
    justify-content space-between
    padding 10px
    border 1px solid $color_grey_lighter
    border-bottom none
    &:last-child
      border-bottom 1px solid $color_grey_lighter
    .RightSide
      span
        cursor pointer
        color $color_primary
</style>

<style lang="stylus">
  .PageChangelogOverview
    background-color $color_grey_lightest
    span
      display block
</style>
